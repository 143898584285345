/*
This is the main entry point for all styles for the web app.
It should import all component stylesheets.
*/

// Global libraries styles
@import 'react-grid-layout/css/styles.css';

// Use duplicate selectors for the light-theme
// stylelint-disable no-duplicate-selectors

@import '../../wildcard/src/global-styles/index.scss';

// Document highlight is the background color for tokens which are matched with
// a result from a document highlight provider. e.g. for references of the token
// currently being hovered over.
//
// Note that we define this before selection highlights so that it will override
// the styles of symbol that has both classes applied.
.sourcegraph-document-highlight {
    background-color: var(--secondary);
}

// Token link is used when we have converted token ranges into links within the blob view
// This primarily removes some default link styles and adds a more suitable focus style.
.sourcegraph-token-link {
    color: inherit;
    text-decoration: inherit;

    &:focus {
        background-color: rgba($blue, 0.1);
    }
}

// Selection highlight is the background color for matched/highlighted tokens,
// e.g. for search results, for identifying the token currently being hovered over,
// or identifying the token the references panel is toggled for.
.selection-highlight,
.selection-highlight-sticky {
    background-color: var(--mark-bg);
}

.match-highlight,
.match-highlight-sticky {
    background-color: var(--mark-bg);
}

// When a match highlight and a selection highlight overlap,
// change the color to be able to distinguish between the highlights.
.match-highlight .selection-highlight,
.match-highlight.selection-highlight,
.selection-highlight .match-highlight {
    .theme-light & {
        background-color: var(--mark-bg-light);
    }

    .theme-dark & {
        background-color: var(--mark-bg-dark);
    }
}

// Pages
@import './api/ApiConsole';

:root {
    --max-homepage-container-width: 65rem;
}
